import React from "react";

import { graphql, Link } from "gatsby";
import Img from "gatsby-image";

export const query = graphql`
    fragment notFound on Language {
        notFound {
            title
            heading
            description
            buttonText
            buttonUrl
            image {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            menu {
                menuItems {
                    title
                    url
                }
            }
        }
    }
`;

const NotFoundSection = ({ notFound }) => {
    return (
        <section className="page-not-found container section mx-auto text-center py-24">
            <div className="flex flex-col mx-auto">
                <h2 className="font-bold text-5xl mb-12">{notFound.heading}</h2>
                <div className="page-not-found__image-wrap mb-12">
                    <Img
                        fluid={notFound.image.childImageSharp.fluid}
                        alt="not found"
                        className="page-not-found__image"
                    />
                </div>
                <p className="mb-12">{notFound.description}</p>
                <Link
                    to={notFound.buttonUrl}
                    className="btn btn--secondary mx-auto md:w-1/2"
                >
                    {notFound.buttonText}
                </Link>
            </div>
        </section>
    );
};

export default NotFoundSection;
