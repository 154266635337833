import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import NotFoundSection from "sections/notFound";

export const query = graphql`
    query($language: String!) {
        ...seo
        lang(key: $language) {
            ...footer
            ...notFound
        }
    }
`;

const NotFoundPage = ({ data, pageContext: { language, translations } }) => {
    const { menu, title, description } = data.lang.notFound;

    return (
        <Layout
            menu={menu}
            footer={data.lang.footer}
            translations={translations}
        >
            <SEO
                title={title}
                description={description}
                lang={language}
                seo={data.seo}
            />
            <NotFoundSection notFound={data.lang.notFound} />
        </Layout>
    );
};

export default NotFoundPage;
